


export const vign = [

  
  {
    src: './photos/Gen/Jungle_radio_02_i.webp',
    width: 3.5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_17_i.webp',
    width: 3.5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_07_i.webp',
    width: 3.5,
    height: 2
  },
  {
    src: './photos/Gen/vesuve_anim.webp',
    width: 3.5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_08_v.webp',
    width: 3.5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_10_v.webp',
    width: 3.5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_13_i.webp',
    width: 3.5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_14_i.webp',
    width: 3.5,
    height: 2
  },
  {
    src: './photos/Gen/ardeche_anim.webp',
    width: 3.5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_15_i.webp',
    width: 3.5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_16_i.webp',
    width: 3.5,
    height: 2
  }
  ,

];




