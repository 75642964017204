


export const photos = [

  
 
  {
    src: './photos/Gen/Jungle_radio_02.webp',
    width: 3.5,
    height: 2
  },  
  {
    src: './photos/Gen/Jungle_radio_17.webp',
    width: 3.5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_07.webp',
    width: 5,
    height: 2
  },
  {
    src: './photos/Gen/vesuve_anim.webp',
    width: 3.5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_08_v.webp',
    width: 3.5,
    height: 2
  }
  ,
  
  {
    src: './photos/Gen/Jungle_radio_10_v.webp',
    width: 3.5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_13.webp',
    width: 5,
    height: 2
  },
  {
    src: './photos/Gen/Jungle_radio_14.webp',
    width: 3.5,
    height: 2
  }
  ,
  {
    src: './photos/Gen/ardeche_anim.webp',
    width: 3.5,
    height: 2
  }
  ,
  {
    src: './photos/Gen/Jungle_radio_15.webp',
    width: 3.5,
    height: 2
  }
  ,
  
  {
    src: './photos/Gen/Jungle_radio_16.webp',
    width: 3.5,
    height: 2
   }

];




